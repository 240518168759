var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('b-form-file', {
    class: [_vm.error.currentFile ? 'is-invalid' : ''],
    attrs: {
      "id": "input",
      "accept": ".xlsx,.xls",
      "placeholder": "seleccione archivo .xlsx",
      "drop-placeholder": "Drop file here..."
    },
    on: {
      "change": _vm.changeFile
    }
  }), _vm.error.currentFile ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Estructura de archivo no valido ")]) : _vm._e(), _c('b-overlay', {
    attrs: {
      "show": _vm.load,
      "rounded": "sm"
    }
  }, [_c('b-table', {
    staticClass: "position-relative mt-2",
    attrs: {
      "items": _vm.rows,
      "responsive": "",
      "fields": _vm.tableColumns,
      "show-empty": "",
      "empty-text": "No matching records found"
    },
    scopedSlots: _vm._u([{
      key: "cell(#)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(skuProducto)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.currentProducts[0].sku) + " ")];
      }
    }, {
      key: "cell(sku)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.sku) + " ")];
      }
    }, {
      key: "cell(nombre)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.nombre) + " ")];
      }
    }, {
      key: "cell(startDate)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.startDate) + " ")];
      }
    }, {
      key: "cell(endDate)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.endDate) + " ")];
      }
    }, {
      key: "cell(startTime)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.startTime) + " ")];
      }
    }, {
      key: "cell(endTime)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.endTime) + " ")];
      }
    }, {
      key: "cell(freeDelivery)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.freeDelivery) + " ")];
      }
    }, {
      key: "cell(tipo)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.tipo) + " ")];
      }
    }, {
      key: "cell(limite)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.limite) + " ")];
      }
    }, {
      key: "cell(bodegas)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.bodegas) + " ")];
      }
    }])
  })], 1), _c('b-button', {
    attrs: {
      "variant": "primary",
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.saveCompositeProducts
    }
  }, [_vm._v(" Guardar ")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }