var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "rounded": "sm"
    }
  }, [_c('div', [_c('b-card', {
    attrs: {
      "title": "Productos Compuestos"
    }
  }, [_c('b-card-body', {
    staticStyle: {
      "min-height": "500px"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mt-1 mb-2",
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('v-select', {
    class: [_vm.error.currentProducts ? 'is-invalid' : ''],
    attrs: {
      "getOptionLabel": _vm.getOptionLabel,
      "options": _vm.products,
      "placeholder": "Escoja sus productos",
      "filterable": "",
      "multiple": _vm.multiple
    },
    on: {
      "search": _vm.getSearchProduct,
      "input": _vm.selectProducts
    }
  }), _vm.error.currentProducts ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Debe seleccionar el o los producto ")]) : _vm._e()], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "sm": "12",
      "b-col": "",
      "md": "6"
    }
  }, [_c('v-select', {
    class: [_vm.error.bodega ? 'is-invalid' : ''],
    attrs: {
      "getOptionLabel": _vm.getOptionLabelBodega,
      "options": _vm.bodegas,
      "placeholder": "Escoja una bodega",
      "filterable": "",
      "multiple": ""
    },
    on: {
      "input": _vm.selectBodegas
    },
    model: {
      value: _vm.data.bodegas,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "bodegas", $$v);
      },
      expression: "data.bodegas"
    }
  }), _vm.error.bodega ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Debe seleccionar una bodega ")]) : _vm._e()], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fecha Inicio",
      "label-for": "vi-fecha-inicio"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-datepicker', {
    class: [_vm.error.startDate ? 'is-invalid' : ''],
    attrs: {
      "min": _vm.currentDate,
      "id": "vi-fecha-inicio",
      "type": "date",
      "placeholder": "FechaInicio",
      "date-format-options": {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      }
    },
    model: {
      value: _vm.data.startDate,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "startDate", $$v);
      },
      expression: "data.startDate"
    }
  }), _vm.error.startDate ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Debe seleccionar la fecha de inicio ")]) : _vm._e()], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fecha Fin",
      "label-for": "vi-fecha-fin"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-datepicker', {
    class: [_vm.error.endDate ? 'is-invalid' : ''],
    attrs: {
      "min": _vm.data.startDate,
      "id": "vi-fecha-fin",
      "type": "date",
      "placeholder": "Fecha Fin",
      "date-format-options": {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      }
    },
    model: {
      value: _vm.data.endDate,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "endDate", $$v);
      },
      expression: "data.endDate"
    }
  }), _vm.error.endDate ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Debe seleccionar la fecha de finalización ")]) : _vm._e()], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Hora Inicio",
      "label-for": "vi-hora-inicio"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    class: [_vm.error.startTime ? 'is-invalid' : ''],
    attrs: {
      "id": "vi-hora-inicio",
      "type": "time",
      "placeholder": "Hora Inicio"
    },
    model: {
      value: _vm.data.startTime,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "startTime", $$v);
      },
      expression: "data.startTime"
    }
  }), _vm.error.startTime ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Debe seleccionar hora de inicio ")]) : _vm._e()], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Hora Fin",
      "label-for": "vi-hora-fin"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    class: [_vm.error.endTime ? 'is-invalid' : ''],
    attrs: {
      "id": "vi-hora-fin",
      "type": "time",
      "placeholder": "Hora Fin"
    },
    model: {
      value: _vm.data.endTime,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "endTime", $$v);
      },
      expression: "data.endTime"
    }
  }), _vm.error.endTime ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Debe seleccionar hora de finalización ")]) : _vm._e()], 1)], 1)], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "b-col": "",
      "md": "4",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tipo de producto compuesto"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var ariaDescribedby = _ref.ariaDescribedby;
        return [_c('b-form-radio-group', {
          attrs: {
            "id": "radio-group-1",
            "options": _vm.optionsTypeCompoiteProduct,
            "aria-describedby": ariaDescribedby,
            "name": "optionsTypeCompoiteProduct"
          },
          on: {
            "change": _vm.changeOptionTypeProduct
          },
          model: {
            value: _vm.typeCompositeProduct,
            callback: function callback($$v) {
              _vm.typeCompositeProduct = $$v;
            },
            expression: "typeCompositeProduct"
          }
        })];
      }
    }])
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', [_c('b-form-input', {
    class: [_vm.error.sku ? 'is-invalid' : ''],
    attrs: {
      "type": "text",
      "placeholder": "Ingrese SKU "
    },
    model: {
      value: _vm.data.sku,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "sku", $$v);
      },
      expression: "data.sku"
    }
  }), _vm.error.sku ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Debe ingrezar sku para el producto compuesto ")]) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', [_c('b-form-input', {
    class: [_vm.error.nombre ? 'is-invalid' : ''],
    attrs: {
      "type": "text",
      "placeholder": "Ingrese Nombre"
    },
    model: {
      value: _vm.data.nombre,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "nombre", $$v);
      },
      expression: "data.nombre"
    }
  }), _vm.error.nombre ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Debe ingrezar nombre del producto compuesto ")]) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', [_c('b-form-input', {
    class: [_vm.error.limite ? 'is-invalid' : ''],
    attrs: {
      "type": "number",
      "disabled": _vm.showInputLimite,
      "placeholder": "Ingrese Limite"
    },
    model: {
      value: _vm.data.limite,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "limite", $$v);
      },
      expression: "data.limite"
    }
  }), _vm.error.limite ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Debe ingresar un limite ")]) : _vm._e()], 1)], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', [_c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": ""
    },
    model: {
      value: _vm.data.freeDelivery,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "freeDelivery", $$v);
      },
      expression: "data.freeDelivery"
    }
  }, [_vm._v(" Envio gratis ")])], 1)], 1)], 1), _c('b-row', [_vm.typeCompositeProduct === 1 ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-card', {
    staticClass: "border"
  }, [_c('b-card-header', {
    staticClass: "px-0 py-0 mb-2"
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-card-title', [_vm._v(" Multiproductos ")])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_vm._v(" TOTAL: $" + _vm._s(_vm.data.total) + " ")])], 1), _c('b-card-body', [_c('b-table', {
    staticClass: "position-relative",
    attrs: {
      "items": _vm.data.currentProducts,
      "responsive": "",
      "fields": _vm.tableColumns,
      "primary-key": "id",
      "show-empty": "",
      "empty-text": "No matching records found"
    },
    scopedSlots: _vm._u([{
      key: "cell(#)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(Producto)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.nombre) + " ")];
      }
    }, {
      key: "cell(Cantidad)",
      fn: function fn(data) {
        return [_c('b-form-input', {
          attrs: {
            "type": "number",
            "min": "0",
            "max": data.item.limite
          },
          on: {
            "input": function input($event) {
              return _vm.changeValuesProduct(data.index, data.item);
            }
          },
          model: {
            value: data.item.cantidad,
            callback: function callback($$v) {
              _vm.$set(data.item, "cantidad", $$v);
            },
            expression: "data.item.cantidad"
          }
        })];
      }
    }, {
      key: "cell(Precio)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.precio) + " ")];
      }
    }, {
      key: "cell(Subtotal)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(Number.parseFloat(data.item.precio * data.item.cantidad).toFixed(2)) + " ")];
      }
    }, {
      key: "cell(Descuento)",
      fn: function fn(data) {
        return [_c('b-form-input', {
          attrs: {
            "type": "number",
            "min": "0",
            "max": "100"
          },
          on: {
            "input": function input($event) {
              return _vm.changeValuesProduct(data.index, data.item);
            }
          },
          model: {
            value: data.item.descuento,
            callback: function callback($$v) {
              _vm.$set(data.item, "descuento", $$v);
            },
            expression: "data.item.descuento"
          }
        })];
      }
    }, {
      key: "cell(Total)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(Number.parseFloat(data.item.precio * data.item.cantidad - data.item.precio * data.item.cantidad * data.item.descuento / 100).toFixed(2)) + " ")];
      }
    }], null, false, 2468161535)
  }), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Imagen [1280 x 480]",
      "label-for": "vi-file-in"
    }
  }, [_c('b-form-file', {
    class: [_vm.error.imagen ? 'is-invalid' : ''],
    attrs: {
      "id": "vi-file-in",
      "accept": ".jpg, .png"
    },
    on: {
      "change": _vm.encodeImageFile
    }
  }), _vm.error.imagen ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Debe seleccionar una imagen para el producto ")]) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_vm.show ? _c('b-img', {
    attrs: {
      "src": _vm.srcData,
      "fluid": "",
      "alt": "Responsive image"
    }
  }) : _vm._e()], 1)], 1)], 1)], 1)], 1) : _vm._e(), _vm.typeCompositeProduct === 2 || _vm.typeCompositeProduct === 3 || _vm.typeCompositeProduct === 4 ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-card', {
    staticClass: "border"
  }, [_c('b-card-header', {
    staticClass: "px-0 py-0 mb-2"
  }, [_c('b-col', [_c('b-card-title', [_vm._v(" Promos " + _vm._s(_vm.typeCompositeProduct === 2 && '2x1' || _vm.typeCompositeProduct === 3 && '3x2' || _vm.typeCompositeProduct === 4 && '2Do a mitad de precio') + " ")])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_vm._v(" TOTAL: $" + _vm._s(_vm.data.total) + " ")])], 1), _c('b-card-body', [_c('b-table', {
    staticClass: "position-relative",
    attrs: {
      "items": _vm.data.currentProducts,
      "responsive": "",
      "fields": _vm.tableColumns2,
      "show-empty": "",
      "empty-text": "No matching records found"
    },
    scopedSlots: _vm._u([{
      key: "cell(#)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(Producto)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.nombre) + " ")];
      }
    }, {
      key: "cell(Cantidad)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.cantidad) + " ")];
      }
    }, {
      key: "cell(Precio)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.precio) + " ")];
      }
    }, {
      key: "cell(Total)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.total) + " ")];
      }
    }, {
      key: "cell(Imagen)",
      fn: function fn(data) {
        return [_c('b-img-lazy', {
          attrs: {
            "src": _vm.urlResized(data.item.imagen),
            "blank-color": "#ccc",
            "width": "100",
            "rounded": ""
          }
        })];
      }
    }], null, false, 2617960565)
  })], 1)], 1)], 1) : _vm._e()], 1)], 1), _c('b-card-footer', [_c('b-row', [_c('b-col', {
    staticClass: "mb-1"
  }, [_c('b-button', {
    staticStyle: {
      "min-width": "138.01px"
    },
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.saveCompositeProduct
    }
  }, [_vm._v(" Guardar ")])], 1), _c('b-col', [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.openBulkLoadModal
    }
  }, [_vm._v(" Carga masiva ")])], 1)], 1)], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "title": "Carga Masiva",
      "ok-only": "",
      "centered": "",
      "ok-variant": "success",
      "modal-class": "modal-success",
      "hide-footer": "",
      "size": "lg"
    },
    model: {
      value: _vm.showBulkLoadModal,
      callback: function callback($$v) {
        _vm.showBulkLoadModal = $$v;
      },
      expression: "showBulkLoadModal"
    }
  }, [_c('b-card-text', [_c('CargaMasiva')], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "title": "Error",
      "ok-only": "",
      "centered": "",
      "ok-title": "Aceptar",
      "ok-variant": "danger",
      "modal-class": "modal-danger"
    },
    model: {
      value: _vm.showErrorModal,
      callback: function callback($$v) {
        _vm.showErrorModal = $$v;
      },
      expression: "showErrorModal"
    }
  }, [_c('b-card-text', [_vm._v(" " + _vm._s(_vm.error) + " ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }