<template>
  <div>
    <b-overlay :show="loading" rounded="sm">
      <div>
        <b-card title="Productos Compuestos">
          <b-card-body style="min-height: 500px">
            <b-row>
              <b-col md="6" sm="12" class="mt-1 mb-2">
                <v-select @search="getSearchProduct" :getOptionLabel="getOptionLabel" :options="products"
                  placeholder="Escoja sus productos" filterable :multiple="multiple" @input="selectProducts"
                  :class="[error.currentProducts ? 'is-invalid' : '']" />
                <div v-if="error.currentProducts" class="invalid-feedback">
                  Debe seleccionar el o los producto
                </div>
              </b-col>
              <b-col sm="12" b-col md="6" class="mt-1">
                <v-select :getOptionLabel="getOptionLabelBodega" v-model="data.bodegas" :options="bodegas"
                  placeholder="Escoja una bodega" filterable multiple @input="selectBodegas"
                  :class="[error.bodega ? 'is-invalid' : '']" />
                <div v-if="error.bodega" class="invalid-feedback">
                  Debe seleccionar una bodega
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label="Fecha Inicio" label-for="vi-fecha-inicio">
                  <b-input-group class="input-group-merge">
                    <b-form-datepicker :min="currentDate" id="vi-fecha-inicio" type="date" placeholder="FechaInicio"
                      v-model="data.startDate" :class="[error.startDate ? 'is-invalid' : '']" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" />
                    <div v-if="error.startDate" class="invalid-feedback">
                      Debe seleccionar la fecha de inicio
                    </div>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Fecha Fin" label-for="vi-fecha-fin">
                  <b-input-group class="input-group-merge">
                    <b-form-datepicker :min="data.startDate" id="vi-fecha-fin" type="date" placeholder="Fecha Fin"
                      v-model="data.endDate" :class="[error.endDate ? 'is-invalid' : '']" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"/>
                    <div v-if="error.endDate" class="invalid-feedback">
                      Debe seleccionar la fecha de finalización
                    </div>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="4">
                <b-form-group label="Hora Inicio" label-for="vi-hora-inicio">
                  <b-input-group class="input-group-merge">
                    <b-form-input id="vi-hora-inicio" type="time" placeholder="Hora Inicio" v-model="data.startTime"
                      :class="[error.startTime ? 'is-invalid' : '']" />
                    <div v-if="error.startTime" class="invalid-feedback">
                      Debe seleccionar hora de inicio
                    </div>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="4">
                <b-form-group label="Hora Fin" label-for="vi-hora-fin">
                  <b-input-group class="input-group-merge">
                    <b-form-input id="vi-hora-fin" type="time" placeholder="Hora Fin" v-model="data.endTime"
                      :class="[error.endTime ? 'is-invalid' : '']" />
                    <div v-if="error.endTime" class="invalid-feedback">
                      Debe seleccionar hora de finalización
                    </div>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col b-col md="4" sm="12" class="mt-1">
                <b-form-group label="Tipo de producto compuesto" v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="radio-group-1" v-model="typeCompositeProduct"
                    :options="optionsTypeCompoiteProduct" :aria-describedby="ariaDescribedby"
                    name="optionsTypeCompoiteProduct" @change="changeOptionTypeProduct"></b-form-radio-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="3">
                <b-form-group>
                  <b-form-input type="text" v-model="data.sku" placeholder="Ingrese SKU "
                    :class="[error.sku ? 'is-invalid' : '']"></b-form-input>
                  <div v-if="error.sku" class="invalid-feedback">
                    Debe ingrezar sku para el producto compuesto
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="3">
                <b-form-group>
                  <b-form-input type="text" v-model="data.nombre" placeholder="Ingrese Nombre"
                    :class="[error.nombre ? 'is-invalid' : '']"></b-form-input>
                  <div v-if="error.nombre" class="invalid-feedback">
                    Debe ingrezar nombre del producto compuesto
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="3">
                <b-form-group>
                  <b-form-input type="number" v-model="data.limite" :disabled="showInputLimite"
                    placeholder="Ingrese Limite" :class="[error.limite ? 'is-invalid' : '']"></b-form-input>
                  <div v-if="error.limite" class="invalid-feedback">
                    Debe ingresar un limite
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="3" class="mt-1">
                <b-form-group>
                  <b-form-checkbox name="check-button" switch v-model="data.freeDelivery">
                    Envio gratis
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" v-if="typeCompositeProduct === 1">
                <b-card class="border">
                  <b-card-header class="px-0 py-0 mb-2">
                    <b-col md="6">
                      <b-card-title> Multiproductos </b-card-title>
                    </b-col>
                    <b-col md="6">
                      TOTAL: ${{ data.total }}
                    </b-col>
                  </b-card-header>
                  <b-card-body>
                    <b-table :items="data.currentProducts" responsive :fields="tableColumns" primary-key="id" show-empty
                      empty-text="No matching records found" class="position-relative">
                      <template #cell(#)="data">
                        {{ data.index + 1 }}
                      </template>
                      <template #cell(Producto)="data">
                        {{ data.item.nombre }}
                      </template>
                      <template #cell(Cantidad)="data">
                        <b-form-input type="number" v-model="data.item.cantidad" min="0" :max="data.item.limite"
                          @input="changeValuesProduct(data.index, data.item)"></b-form-input>
                      </template>
                      <template #cell(Precio)="data">
                        {{ data.item.precio }}
                      </template>
                      <template #cell(Subtotal)="data">
                        {{ Number.parseFloat(data.item.precio * data.item.cantidad).toFixed(2) }}
                      </template>
                      <template #cell(Descuento)="data">
                        <b-form-input type="number" v-model="data.item.descuento" min="0" max="100"
                          @input="changeValuesProduct(data.index, data.item)"></b-form-input>
                      </template>
                      <template #cell(Total)="data">
                        {{ Number.parseFloat((data.item.precio * data.item.cantidad) - (((data.item.precio *
                          data.item.cantidad) * data.item.descuento) / 100)).toFixed(2) }}
                      </template>
                      <!-- <template #cell(Limite)="data">
                                  <b-form-input type="number" v-model="data.item.limite"></b-form-input>
                                </template> -->
                    </b-table>
                    <b-row>
                      <b-col md="6">
                        <b-form-group label="Imagen [1280 x 480]" label-for="vi-file-in">
                          <b-form-file id="vi-file-in" accept=".jpg, .png" @change="encodeImageFile"
                            :class="[error.imagen ? 'is-invalid' : '']" />
                          <div v-if="error.imagen" class="invalid-feedback">
                            Debe seleccionar una imagen para el producto
                          </div>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-img v-if="show" :src="srcData" fluid alt="Responsive image" />
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-card>
              </b-col>
              <b-col md="12"
                v-if="typeCompositeProduct === 2 || typeCompositeProduct === 3 || typeCompositeProduct === 4">
                <b-card class="border">
                  <b-card-header class="px-0 py-0 mb-2">
                    <b-col>
                      <b-card-title>
                        Promos {{
                          typeCompositeProduct === 2 && '2x1' ||
                          typeCompositeProduct === 3 && '3x2' ||
                          typeCompositeProduct === 4 && '2Do a mitad de precio'
                        }}
                      </b-card-title>
                    </b-col>
                    <b-col md="6">
                      TOTAL: ${{ data.total }}
                    </b-col>
                  </b-card-header>
                  <b-card-body>
                    <b-table :items="data.currentProducts" responsive :fields="tableColumns2" show-empty
                      empty-text="No matching records found" class="position-relative">
                      <template #cell(#)="data">
                        {{ data.index + 1 }}
                      </template>
                      <template #cell(Producto)="data">
                        {{ data.item.nombre }}
                      </template>
                      <template #cell(Cantidad)="data">
                        {{ data.item.cantidad }}
                      </template>
                      <template #cell(Precio)="data">
                        {{ data.item.precio }}
                      </template>
                      <template #cell(Total)="data">
                        {{ data.item.total }}
                      </template>
                      <!-- <template #cell(Limite)="data">
                                  <b-form-input type="number" v-model="data.item.limite"></b-form-input>
                                </template> -->
                      <template #cell(Imagen)="data">
                        <b-img-lazy :src="urlResized(data.item.imagen)" blank-color="#ccc" width="100" rounded />
                      </template>
                    </b-table>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </b-card-body>
          <b-card-footer>
            <b-row>
              <b-col class="mb-1">
                <b-button style="min-width: 138.01px;" variant="primary" @click="saveCompositeProduct"> Guardar
                </b-button>
              </b-col>
              <b-col>
                <b-button variant="primary" @click="openBulkLoadModal">
                  Carga masiva
                </b-button>
              </b-col>
            </b-row>
          </b-card-footer>
        </b-card>
      </div>
      <b-modal title="Carga Masiva" ok-only v-model="showBulkLoadModal" centered ok-variant="success"
        modal-class="modal-success" hide-footer size="lg">
        <b-card-text>
          <CargaMasiva />
        </b-card-text>
      </b-modal>
    </b-overlay>
    <b-modal title="Error" ok-only v-model="showErrorModal" centered ok-title="Aceptar" ok-variant="danger"
      modal-class="modal-danger">
      <b-card-text>
        {{ error }}
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BAlert,
  BOverlay,
  BCardBody,
  BCardTitle,
  BCardHeader,
  BCardFooter,
  BCardText,
  BListGroupItem,
  BFormRadioGroup,
  BFormGroup,
  BFormDatepicker,
  BTable,
  BInputGroup,
  BFormInput,
  BModal,
  BButton,
  BFormSelect,
  BFormCheckbox,
  BFormFile,
  BImg,
  BImgLazy,
} from "bootstrap-vue";
import vSelect from "vue-select";

import { getAuthToken } from "@/auth/utils";
import CargaMasiva from "./components/CargaMasiva.vue";

import draggable from "vuedraggable";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAlert,
    BOverlay,
    BCardBody,
    BCardTitle,
    BCardHeader,
    BCardFooter,
    vSelect,
    draggable,
    BListGroupItem,
    BFormRadioGroup,
    BFormGroup,
    BFormDatepicker,
    BFormInput,
    BInputGroup,
    BTable,
    BModal,
    BButton,
    BFormSelect,
    BFormCheckbox,
    ToastificationContent,
    BFormFile,
    BImg,
    BImgLazy,
    BFormFile,
    BCardText,
    CargaMasiva
  },
  data() {
    return {
      products: [],
      bodegas: [],
      page: 1,
      loading: false,
      multiple: true,
      showErrorModal: false,
      showBulkLoadModal: false,
      showInputLimite: true,
      pageLength: 10,
      searchTerm: "",
      error: "",
      srcData: null,
      show: false,
      typeCompositeProduct: null,
      error: {
        currentProducts: false,
        bodega: false,
        imagen: false,
        startDate: false,
        endDate: false,
        startTime: false,
        endTime: false,
        tipo: false,
        sku: false,
        nombre: false,
        limite: false
      },
      data: {
        currentProducts: [],
        startDate: null,
        endDate: null,
        startTime: null,
        endTime: null,
        freeDelivery: false,
        tipo: null,
        total: 0,
        bodegas: [],
        imagen: null,
        sku: null,
        nombre: null,
        limite: null,
      },
      url: null,
      optionsPercentage: [],
      optionsTypeCompoiteProduct: [],
      tableColumns: [
        "#",
        "Producto",
        "Cantidad",
        { key: "Precio", label: "Precio Original" },
        "Subtotal",
        "Descuento",
        "Total"
      ],
      tableColumns2: [
        "#",
        "Producto",
        "Cantidad",
        { key: "Precio", label: "Precio Original" },
        "Total",
        "Imagen"
      ],
      currentDate: new Date()
    }
  },
  mounted() {
    this.optionsPercentage = Array.from({ length: 100 - 0 + 1 }, (v, i) => i + 0)
    this.getInformation()
  },
  methods: {
    getInformation() {
      this.loading = true
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/Bodegas",
          body: JSON.stringify({
            otp: "V",
            empresa: 2,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          this.loading = false;
          if (res.data.bOk) {
            this.bodegas = res.data.bodegas
          } else {
            this.error = res.data.mensaje;
            this.showErrorModal = true;
          }
        })
        .catch((e) => {
          this.loading = false;
          this.error = e.message;
          this.showErrorModal = true;
        });
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/productos/ObtenerTipoCompuesto",
          body: JSON.stringify({
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.optionsTypeCompoiteProduct = res.data.tipos.map(t => (
              {
                text: t.tipo,
                value: t.idTipo
              }
            ))
          } else {
            this.error = res.data.mensaje;
            this.showErrorModal = true;
          }
        })
        .catch((e) => {
          //load(false)
          this.error = e.message;
          this.showErrorModal = true;
        });
    },
    getSearchProduct(search, load) {
      if (search.length >= 3) {
        load(true)
        this.$http
          .post(this.$store.state.app.middlewareURL, {
            path: "/Productos/ConsultaProductosAdmin",
            body: JSON.stringify({
              Filas: this.pageLength,
              Pagina: this.page,
              TipoProd: "SIM",
              NombreProducto: search,
              tokenSesion: getAuthToken(),
            }),
          })
          .then((res) => {
            load(false)
            if (res.data.bOk) {
              this.products = res.data.lstProductos;
            } else {
              this.error = res.data.mensaje;
              this.showErrorModal = true;
            }
          })
          .catch((e) => {
            load(false)
            this.error = e.message;
            this.showErrorModal = true;
          });
      }
    },
    getOptionLabel(option) {
      return option.sku + ' - ' + option.nombre
    },
    getOptionLabelBodega(option) {
      return option.nombreBodega
    },
    changeOptionTypeProduct() {
      this.data.tipo = this.typeCompositeProduct
      const size = this.data.currentProducts.length
      this.multiple = true
      if (
        this.typeCompositeProduct === 2 ||
        this.typeCompositeProduct === 3 ||
        this.typeCompositeProduct === 4
      ) {
        this.multiple = false
        if (size > 1) {
          this.data.currentProducts = []
          this.data.total = 0
          return
        }
        if (size) {
          let cantAux = 2
          if (this.typeCompositeProduct === 3)
            cantAux = 3
          this.data.currentProducts[0].cantidad = cantAux
          this.selectProducts(this.data.currentProducts[0])
          return
        }
      }
      this.data.total = 0
    },
    selectBodegas(e) {
      if (e.length > 1) {
        this.showInputLimite = true
        return
      }
      this.showInputLimite = false
    },
    selectProducts(e) {
      if (e === null)
        return
      this.data.currentProducts = []
      if (!Array.isArray(e)) {
        let precio = Number.parseFloat(e.precio).toFixed(2)
        precio = Number(precio)
        let total = precio
        let cantidad = 2
        if (this.typeCompositeProduct === "3x2") {
          cantidad = 3
          total = Number.parseFloat(2 * precio).toFixed(2)
        }
        if (this.typeCompositeProduct === "2Do")
          total = Number.parseFloat(precio * 1.25).toFixed(2)
        total = Number(total)
        this.data.total = total
        this.data.currentProducts = [{
          id: e.id,
          sku: e.sku,
          nombre: e.nombre,
          precio,
          cantidad,
          total,
          imagen: e.imagen
        }]
        return
      }
      this.data.currentProducts = e.map(p => (
        {
          id: p.id,
          sku: p.sku,
          nombre: p.nombre,
          precio: Number.parseFloat(p.precio).toFixed(2),
          cantidad: 0,
          subtotal: 0,
          descuento: 0,
          total: 0,
          imagen: p.imagen
        }
      ))
    },
    changeValuesProduct(index, product) {
      let cantidad
      product.cantidad ?
        cantidad = parseInt(product.cantidad) :
        cantidad = 0
      let descuento = Number(product.descuento)
      let subtotal = Number.parseFloat(cantidad * product.precio).toFixed(2)
      let total = Number.parseFloat(subtotal - ((subtotal * descuento) / 100)).toFixed(2)

      const precio = Number(product.precio)
      subtotal = Number(subtotal)
      total = Number(total)

      this.data.currentProducts[index] = {
        ...product,
        precio,
        cantidad,
        descuento,
        subtotal,
        total
      }

      const tAux = this.data.currentProducts.reduce((accumulator, currentValue) => accumulator + currentValue.total, 0)

      this.data.total = Number.parseFloat(tAux).toFixed(2)
    },
    urlResized(urlOriginal) {
      try {
        const url = new URL(urlOriginal);
        url.pathname = "/fit-in/200x200" + url.pathname;
        return url.href;
      } catch (error) {
        return "https://mrconfeccoes.com.br/wp-content/uploads/2018/03/default.jpg";
      }
    },
    encodeImageFile(element) {
      const self = this
      const filesSelected = element.currentTarget.files;
      if (filesSelected.length > 0) {
        const fileToLoad = filesSelected[0];
        const fileReader = new FileReader();

        fileReader.onload = function (fileLoadedEvent) {
          self.show = true
          self.data.imagen = fileLoadedEvent.target.result
          self.srcData = fileLoadedEvent.target.result;
        };
        fileReader.readAsDataURL(fileToLoad);
      }
    },
    async saveCompositeProduct() {
      const validate = this.validate()
      const t = this.data.freeDelivery
      this.data.freeDelivery = 0
      if (t)
        this.data.freeDelivery = 1
      const validSKU = await this.validSku()
      console.log(validate && validSKU)
      if (validate && validSKU) {
        const currentProducts = this.data.currentProducts.map(p => ({
          id: p.id,
          cantidad: p.cantidad,
          descuento: p.descuento
        }))
        console.log(currentProducts)
        const { startDate, endDate, startTime, endTime, freeDelivery, tipo, limite, nombre, bodegas, sku } = this.data
        const bodegasAux = bodegas.map(b => ({
          id: b.idBodega,
          limite: limite === null ? 0 : limite
        }))
        const body = {
          tokenSesion: getAuthToken(),
          sku,
          currentProducts,
          startDate,
          endDate,
          startTime,
          endTime,
          freeDelivery,
          tipo,
          nombre,
          bodegas: bodegasAux,
        }
        this.$http
          .post(this.$store.state.app.middlewareURL, {
            path: "/productos/CrearProductoCompuesto",
            body: JSON.stringify(body),
          })
          .then((res) => {
            if (res.data.bOk) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  icon: "CheckIcon",
                  text: "Se Guardo correctamente.",
                  title: "Exito",
                  variant: "success",
                },
              });
              if (this.typeCompositeProduct === 1) {
                this.saveImage(res.data.mensaje)
              } else {
                this.url = this.data.currentProducts[0].imagen
                this.updateImageCmpProduct(res.data.mensaje)
              }
              this.data = {
                currentProducts: [],
                startDate: null,
                endDate: null,
                startTime: null,
                endTime: null,
                freeDelivery: false,
                tipo: null,
                total: 0
              }
              this.products = []
              this.srcData = null
            } else {
              this.error = res.data.mensaje;
              this.showErrorModal = true;
            }
          })
          .catch((e) => {
            this.error = e.message;
            this.showErrorModal = true;
          });

      }

    },
    saveImage(id) {      
      this.$http
        .post(this.$store.state.app.middlewareAWSURL, {
          path: "/Administracion/AWSUpload",
          body: JSON.stringify({
            files: this.data.imagen,
            folder: 'CompoundProduct',
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.url = res.data[0].url;
            this.data = {
              currentProducts: [],
              startDate: null,
              endDate: null,
              startTime: null,
              endTime: null,
              freeDelivery: false,
              tipo: null,
              total: 0
            }
            this.products = []
            this.srcData = null
            this.updateImageCmpProduct(id)
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              text: `Ocurrió un error al subir la imagen (${e.message}).`,
              variant: "danger",
            },
          });
        });
    },
    openBulkLoadModal() {
      this.showBulkLoadModal = true
    },
    updateImageCmpProduct(id) {
      const body = {
        productoId: id,
        url: this.url,
        tokenSesion: getAuthToken(),
      }
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Productos/ActualizarImagenProductoCompuesto",
          body: JSON.stringify(body),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: "CheckIcon",
                text: "Se Guardo correctamente.",
                title: "Éxito",
                variant: "success",
              },
            });
          } else {
            this.error = res.data.mensaje;
            this.showErrorModal = true;
          }
        })
        .catch((e) => {
          this.error = e.message;
          this.showErrorModal = true;
        });
    },
    async validSku() {
      const sku = this.data.sku
      try {
        const res = await this.$http.post(this.$store.state.app.middlewareURL, {
          path: `/productos/ValidarSkuCompuesto?sku=${sku}`,
          body: JSON.stringify({
            tokenSesion: getAuthToken(),
          }),
        })

        if (res.data.bOk) {
          return true
        }
        this.error = res.data.mensaje;
        this.showErrorModal = true;
        return false
      } catch (e) {
        this.error = e.message;
        this.showErrorModal = true;
        return false
      }

    },
    validate() {
      this.error.startDate = this.data.startDate === null
      this.error.endDate = this.data.endDate === null
      this.error.currentProducts = this.data.currentProducts.length === 0
      this.error.tipo = this.data.tipo === null
      this.error.bodega = this.data.bodegas.length === 0
      if (this.typeCompositeProduct === 1)
        this.error.imagen = this.data.imagen === null
      this.error.nombre = this.data.nombre === null
      this.error.sku = this.data.sku === null

      if (
        this.error.startDate ||
        this.error.endDate ||
        this.error.startTime ||
        this.error.endTime ||
        this.error.currentProducts ||
        this.error.tipo ||
        this.error.bodega ||
        this.error.imagen ||
        this.error.nombre ||
        this.error.limite ||
        this.error.sku
      ) {
        return false
      }
      return true
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.list-group-item {
  transition: all 1s;
}

.f1 {
  flex: 1;
}
</style>