<template>
    <div>  
        <div>
            <b-form-file
              id="input"
              @change="changeFile"
              accept=".xlsx,.xls"
              placeholder="seleccione archivo .xlsx"
              drop-placeholder="Drop file here..."
              :class="[error.currentFile ? 'is-invalid' : '']"
            ></b-form-file>
            <div v-if="error.currentFile" class="invalid-feedback">
                Estructura de archivo no valido 
            </div>
            <b-overlay :show="load" rounded="sm">
                <b-table
                    :items="rows"
                    responsive
                    :fields="tableColumns"
                    show-empty
                    empty-text="No matching records found"
                    class="position-relative mt-2"
                >
                    <template #cell(#)="data">
                        {{ data.index + 1 }}
                    </template>
                    <template #cell(skuProducto)="data">
                        {{ data.item.currentProducts[0].sku }}
                    </template>
                    <template #cell(sku)="data">
                        {{ data.item.sku }}
                    </template>
                    <template #cell(nombre)="data">
                        {{ data.item.nombre }}
                    </template>
                    <template #cell(startDate)="data">
                        {{ data.item.startDate }}
                    </template>
                    <template #cell(endDate)="data">
                        {{ data.item.endDate }}
                    </template>
                    <template #cell(startTime)="data">
                        {{ data.item.startTime }}
                    </template>
                    <template #cell(endTime)="data">
                        {{ data.item.endTime }}
                    </template>
                    <template #cell(freeDelivery)="data">
                        {{ data.item.freeDelivery }}
                    </template>
                    <template #cell(tipo)="data">
                        {{ data.item.tipo }}
                    </template>
                    <template #cell(limite)="data">
                        {{ data.item.limite }}
                    </template>
                    <template #cell(bodegas)="data">
                        {{ data.item.bodegas }}
                    </template>                    
                </b-table>
            </b-overlay>
            <b-button variant="primary" @click="saveCompositeProducts" :disabled="disabled">
                Guardar
            </b-button>
        </div>
    </div>
  </template>


<script>

  import {
    BFormFile,
    BOverlay,
    BTable,
    BButton
  } from 'bootstrap-vue' 

  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import { getAuthToken } from "@/auth/utils";
  import readXlsxFile from 'read-excel-file'

  export default {
    components: {
        BFormFile,
        BOverlay,
        BTable,
        BButton,
        ToastificationContent,
    },
    data() {
        return {
            load: false,
            disabled: true,
            schema: {
                'Sku Producto': {
                    prop: "skuProducto",
                    required: true,
                    type: (value) => this.validSKU(value),                
                },
                'Sku compuesto': {
                    prop: "sku",
                    type: (value) => this.validSKU(value), 
                    required: true
                },
                'Nombre Compuesto': {
                    prop: "nombre",
                    type: String,
                    required: true
                },
                'Fecha inicio': {
                    prop: "startDate",
                    type: (value) => this.validIsDate(value),
                    required: true
                },
                'Fecha fin': {
                    prop: "endDate",
                    type: (value) => this.validIsDate(value),
                    required: true
                },
                'Hora inicio': {
                    prop: "startTime",
                    type: String,
                    required: false
                },
                'Hora fin': {
                    prop: "endTime",
                    type: String,
                    required: false
                },
                'Entrega Gratis': {
                    prop: "freeDelivery",
                    type: (value) => {
                        if (value != 0 && value != 1)
                            throw new Error('invalid')
                        return value
                    }
                },
                'Tipo Compuesto': {
                    prop: "tipo",
                    type: (value) => this.validIsNumber(value),
                    required: true
                },
                'Limite': {
                    prop: 'limite',
                    type: (value) => this.validIsNumber(value),
                    required: false
                },
                'Bodegas': {
                    prop: 'bodegas',
                    type: (value) => this.validBodega(value),
                    required: true
                }
            },
            rows: [],
            data: {
                compositeProductsList: []
            },
            error: {
                currentFile: false
            },
            tableColumns: [
                '#',
                { key: "skuProducto", label: "SKU Producto"},
                { key: "sku", label: "SKU compuesto"},
                { key: "nombre", label: "Nombre compuesto"},
                { key: "startDate", label: "Fecha inicio"},
                { key: "endDate", label: "Fecha fin"},
                { key: "startTime", label: "Hora Inicio"},
                { key: "endTime", label: "Hora fin"},
                { key: "freeDelivery", label: "Entrega gratis"},
                { key: "tipo", label: "Tipo compuesto"},
                { key: "limite", label: "Limite"},
                { key: "bodegas", label: "Bodegas"}
            ]
        }
    },
    mounted() {},
    methods: {
        changeFile(element) {
            this.load = true
            const schema = this.schema
            const currentFile = element.currentTarget.files
            readXlsxFile(currentFile[0],{ schema }).then(({ rows, errors }) => {
                this.error.currentFile = errors.length !== 0 || rows.length === 0
                this.load = false

                console.log('Error file: ', errors, ' - ', this.error.currentFile)

                if ( this.error.currentFile )
                  return 
                
                this.disabled = false

                this.rows = rows.map( r => {
                    const {
                        skuProducto,
                        sku,
                        nombre,
                        startDate,
                        endDate,
                        startTime,
                        endTime,
                        freeDelivery,
                        tipo,
                        limite,
                        bodegas
                    } = r

                    const currentProducts = [
                        {
                            sku: skuProducto
                        }
                    ]

                    const bodegasAux = bodegas + ''
                    const bodegasListAux = bodegasAux.split(';')
                    console.log(bodegasListAux.length === 1)
                    console.log(limite)
                    const limitAux = bodegasListAux.length === 1 && limite ? limite : 0
                    const bodegasList = bodegasListAux.map( b => ({
                        id: Number(b),
                        limite: limitAux
                    }))

                    const res = {
                        sku,
                        nombre,
                        startDate,
                        endDate,
                        startTime,
                        endTime,
                        freeDelivery,
                        tipo,
                        currentProducts
                    }

                    this.data.compositeProductsList.push({
                        ...res,
                        bodegas: bodegasList
                    })
                    return {
                        ...res,
                        limite: limitAux,
                        bodegas
                    }
                })
            })
        },
        saveCompositeProducts() {
            console.log(!this.error.currentFile)
            if ( !this.error.currentFile ) {
                this.load = true
                this.$http
                .post(this.$store.state.app.middlewareURL, {
                    path: "/productos/cargaMasivaCompuesto",
                    body:  JSON.stringify({
                        productos:this.data.compositeProductsList,
                        tokenSesion: getAuthToken(),
                    }),
                })
                .then((res) => {
                    this.load = false
                    console.log(res)
                    if (res.data.bOk) {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                icon: "CheckIcon",
                                text: res.data.mensaje,
                                title: "Exito",
                                variant: "success",
                            },
                        });
                        this.rows = []
                    } else {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Error",
                                icon: "AlertTriangleIcon",
                                text: res.data.mensaje,
                                variant: "danger",
                            },
                        });
                        this.error = res.data.mensaje;
                        this.showErrorModal = true;
                    }
                })
                .catch((e) => {
                    this.load = false
                    this.error = e.message;
                    this.showErrorModal = true;
                });
            }
        },
        validate() {},
        validIsNumber (value) {
            const num = new Number(value)
            if (!(num instanceof Number))
                throw new Error('invalid')
            return  Number(value)
        },
        validLimit(value) {

            if (value == 0 || value == "" || value == null || value == undefined)
                return 0
            this.validIsNumber(value)
            return value
        },
        validIsDate(value) {
            const dateAux = value.split('-')
            if (dateAux.length !== 3) 
                throw new Error('invalid')
            
            const [ year, month, day ] = dateAux

            
            if ( year.length != 4)
                throw new Error('invalid')
            
            if ( month.length !== 2 )
                throw new Error('invalid')
            
            if ( day.length != 2 )
                throw new Error('invalid')

            return value
        },
        validSKU(value) {
            if (value.length < 3 ) {
                throw new Error('invalid')
            }
            return value
        },
        validBodega(value) {
            const valueAux = value + ""
            const arrBdg = valueAux.split(';')
            if ( arrBdg.length === 0 ) {
                throw new Error('invalid')
            }
            
            arrBdg.map( e => this.validIsNumber(e))
            return value
        }
    }
  }
</script>